const initLapToggles = function() {
    lapToggle.addToggle();
}

const initBopToggles = function() {
    // BOP toggles JS
    if ($('#trading-content-dropper').length !== 0) {
        $('#trading-content-dropper').on('click', function () {
            $('#trading-issues').slideToggle(400);
        });
    }
}

const lapToggle = {
    // Add toggle to the togglable class
    addToggle: function() {
        $('.togglable').on('click', function(event) {
            event.stopPropagation();
            lapToggle.swapToggle(this);
            lapToggle.appendChildren(this);
            $(this).children('li').toggle({
                duration: 400,
                easing: 'swing'
            });
        });
        $('.toggled').on('click', function(event) {
            event.stopPropagation();
            lapToggle.addCollapseEvent(this);
        });
    },

    addCollapseEvent: function(element) {
        lapToggle.swapToggle(element);
        $(element).next().slideToggle();
        lapToggle.collapseChildren([element]);
        lapToggle.handleEvents();
    },

    collapseChildren: function(elements) {
        if (typeof elements !== 'object' || elements === null) {
            return false;
        }
        elements.map(function (ele, ind, arr) {
            id = $(ele).children('a').data('id');
            let selector = 'li[data-parent=' + id + ']';
            let eles = $(selector);
            list = [];
            eles.map(function (i, e, a) {
                list.push(e);
            });
            lapToggle.collapseChildren(list);
            if (!$($(event.target)[0]).is($(ele)) && $(ele).hasClass('toggled')) {
                $(ele).next().slideToggle();
                lapToggle.swapToggle(ele);
            }
        });
    },

    swapToggle: function(element) {
        if ($(element).hasClass('togglable')) {
            $(element).removeClass('togglable');
            $(element).addClass('toggled');
        } else {
            $(element).addClass('togglable');
            $(element).removeClass('toggled');
        }
    },

    getDs: function(loc) {
        let key;
        let ds = loc.split('/');
        ds.map(function (ele, ind, arr) {
            if (ele === "ds") {
                key = ind + 1;
            }
        });
        ds = ds[key];
        return ds;
    },

    appendChildren: function(element) {
        let ds = lapToggle.getDs(window.location.href);

        if ($(element).hasClass('loaded')) {
            $(element).next().slideToggle();
            return false;
        }

        if ($(element).data('scotland') === 'Scotland') {
            target = '/asynccategory/getscottishdocuments/id/'
        } else {
            target = '/asynccategory/getchildren/id/';
        }

        $(element).addClass('loading');

        $.ajax(target, {
            data: {id:$(element).children('a').data('id'), ds:ds},
            method: 'POST',
            success: function(data) {
                try {
                    data = JSON.parse(data);
                } catch(err) {
                    console.log(err);
                    data = [
                        {
                            id: 'error',
                            document_type: "ERROR",
                            name: 'Sorry, there was a problem loading the profiles. Please refresh the page and try again. If the problem persists, please contact support.'
                        }
                    ];
                }
                data = lapToggle.filterClicked(data, element);

                // Make RPS's come at the top of the list
                data = lapToggle.reOrderDocs(data);

                let appendstring = '<ul style="display:none" data-parent="'+$(element).children('a').data('id')+'">';
                let depth = $(element).data('depth');
                depth = depth + 1;

                data.map(function (ele, ind, arr) {
                    if (!ele.hasOwnProperty('type')) {
                        appendstring += '<li data-parent="'+$(element).children('a').data('id')+'" data-depth="'+depth+'" class="togglable">';
                        appendstring += '<img src="/themes/default/img/LAP-icon.png" class="bullet" alt="">';
                        appendstring += '<a data-id="'+ele.id+'">'+ele.name+'</a></li>';
                    } else {
                        if (ele.type === "RSP") {
                            appendstring += '<li data-parent="'+$(element).children('a').data('id')+'" data-depth="'+depth+'" class="document-li RSP">';
                        } else if (ele.type === "ERROR") {
                            appendstring += '<li data-parent="'+$(element).children('a').data('id')+'" data-depth="'+depth+'" class="document-li error">';
                        } else {
                            appendstring += '<li data-parent="'+$(element).children('a').data('id')+'" data-depth="'+depth+'" class="document-li">';
                        }
                        appendstring += '<img src="/themes/default/img/LAP-icon.png" class="bullet" alt="">';

                        if (ele.type === "RSP") {
                            appendstring += '<a data-id="'+ele.id+'"  href="/document/view/ds/'+ds+'/k/0/o//format/html/id/'+ele.id+'/track/true/">'+ele.name+'</a>';
                            appendstring += '<span> - Regional Profile</span>';
                        } else {
                            appendstring += '<a data-id="'+ele.id+'"  href="/document/view/ds/'+ds+'/k/0/o//format/html/id/'+ele.id+'/track/true/">'+ele.name+'</a>';
                        }
                        appendstring += '</li>';
                    }
                });
                appendstring += '</ul>';
                $(element).after(appendstring);

                lapToggle.handleEvents();
            },
            beforeSend: function() {

            },
            complete: function() {
                $(element).removeClass('loading');
                $($(element)).addClass('loaded');
                $($(element).next()).slideToggle();
            }
        });
    },

    reOrderDocs: function(data) {
        let arr = [];
        data.map(function (ele, ind) {
            if (ele.hasOwnProperty('type')) {
                if (ele.type === "RSP") {
                    arr.push(ele);
                    data[ind] = null;
                }
            }
        });
        data.map(function (ele) {
            if (ele !== null) {
                arr.push(ele);
            }
        });
        return arr;
    },

    handleEvents: function() {
        $('.togglable').unbind();
        $('.toggled').unbind();
        lapToggle.addToggle();
    },

    filterClicked: function(data, element) {
        let array = [];
        data.map(function(ele, ind, arr) {
            if (ele.id !== $(element).children('a').data('id')) {
                array.push(ele);
            }
        });
        return array;
    }
};

export {initLapToggles, initBopToggles}
